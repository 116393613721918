import React, {useEffect, useState} from "react";
import {Alert, Button, Col, ConfigProvider, DatePicker, Divider, Row, Select, Spin, Table} from "antd";
import i18next from "i18next";
import * as UserBackend from "../backend/UserBackend";
import * as Setting from "../Setting";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import locale from "antd/locale/ru_RU";
import * as ReportBackend from "../backend/ReportBackend";
import {
  applicationListColumns, eventsCountBySeverityColumns, groupedBotAttacksColumns,
  incidentsCountInfoColumns,
  mostAttackedApplicationColumns, topAttackOriginsByIPColumns, topAttackTypesColumns, topAttacksOriginsByGeoColumns
} from "./ReportDataTablesDescription";
dayjs.locale("ru");
const {RangePicker} = DatePicker;
const rangePresets = [
  {label: i18next.t("general:Today"), value: [dayjs(), dayjs()]},
  {label: i18next.t("report:Yesterday"), value: [dayjs().add(-1, "day"), dayjs().add(-1, "day")]},
  {label: i18next.t("report:Last 7 days"), value: [dayjs().add(-7, "day"), dayjs()]},
  {label: i18next.t("report:Last 30 days"), value: [dayjs().add(-30, "day"), dayjs()]},
  {label: i18next.t("report:This month"), value: [dayjs().startOf("month"), dayjs().endOf("month")]},
  {
    label: i18next.t("report:Previous month"),
    value: [dayjs().add(-1, "month").startOf("month"), dayjs().add(-1, "month").endOf("month")],
  },
];
const languages = [
  {label: "ru", value: "ru"},
  {label: "en", value: "en"},
];
const ReportsPage = (props) => {
  // States to control username select
  const [organization, setOrganization] = useState(localStorage.getItem("organization"));
  const [usernames, setUsernames] = useState([]);
  // Data to generate report
  const [dateSelectorRange, setDateSelectorRange] = useState({startDate: "", endDate: ""});
  const [selectedUserUUID, setSelectedUserUUID] = useState("");
  const [reportLanguage, setReportLanguage] = useState("ru");
  // Helpers states
  const [isReportButtonDisabled, setIsReportButtonDisabled] = useState(true);
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  // Report data
  const [reportData, setReportData] = useState(undefined);
  useEffect(() => {
    getUsers(organization);
    setSelectedUserUUID("");
  }, [organization]);

  const getUsers = (organizationName) => {
    (Setting.isDefaultOrganizationSelected(props.account)
      ? UserBackend.getGlobalUsers("", "")
      : UserBackend.getUsers(organizationName)
    )
      .then((res) => {
        if (res.status === "error") {
          Setting.showMessage("error", res.msg);
          return;
        }
        setUsernames(res.filter(user => user.tag === "client").map(user => {
          return {label: user.name, value: user.id};
        }));
      });

  };
  // Check input data for enable/disable report button
  useEffect(() => {
    selectedUserUUID !== "" && dateSelectorRange.startDate !== "" && dateSelectorRange.endDate !== "" ? setIsReportButtonDisabled(false)
      : setIsReportButtonDisabled(true);
    setReportData(undefined);
  }, [dateSelectorRange, selectedUserUUID, reportLanguage]);
  const getReportData = () => {
    setIsLoadingReport(true);
    ReportBackend.getReport(selectedUserUUID, dateSelectorRange.startDate, dateSelectorRange.endDate, reportLanguage)
      .then((response) => {
        setIsLoadingReport(false);
        if (response.status === "error") {
          setReportData(null);
          return;
        }
        setReportData(response);
      })
      .catch(error => {
        Setting.showMessage("error", `${i18next.t("general:Failed to connect to server")}: ${error}`);
        setReportData(null);
      });
  };
  const ReportDataTables = ({reportData}) => (
    reportData === null
      ? <Alert message={i18next.t("report:Report can`t be formed")} description={i18next.t("report:Contact support for more information")} type={"error"} />
      : reportData === undefined ? <></> :
        (
          <>
            <Table bordered style={{marginBottom: "20px"}}
              columns={applicationListColumns[reportLanguage]} dataSource={reportData["application_list"]} size="middle" pagination={false} />
            <Table bordered style={{marginBottom: "20px"}}
              columns={mostAttackedApplicationColumns[reportLanguage]} dataSource={reportData["most_attacked_applications"]} size="middle" pagination={false} />
            <Table bordered style={{marginBottom: "20px"}}
              columns={incidentsCountInfoColumns[reportLanguage]} dataSource={[reportData["incidents_count_info"]]} size="middle" pagination={false} />
            <Table bordered style={{marginBottom: "20px"}}
              columns={topAttackTypesColumns[reportLanguage]} dataSource={reportData["top_attack_types"]} size="middle" pagination={false} />
            <Table bordered style={{marginBottom: "20px"}}
              columns={eventsCountBySeverityColumns[reportLanguage]} dataSource={reportData["events_count_by_severity"]} size="middle" pagination={false} />
            <Table bordered style={{marginBottom: "20px"}}
              columns={topAttackOriginsByIPColumns[reportLanguage]} dataSource={reportData["top_attack_origins_by_ip"]} size="middle" pagination={false} />
            <Table bordered style={{marginBottom: "20px"}}
              columns={topAttacksOriginsByGeoColumns[reportLanguage]} dataSource={reportData["top_attack_origins_by_geo"]} size="middle" pagination={false} />
            <Table bordered
              columns={groupedBotAttacksColumns[reportLanguage]} dataSource={reportData["grouped_bot_attacks"]} size="middle" pagination={false} />
          </>
        )
  );
  // Event listener for updates in localStorage
  useEffect(() => {
    const handleOrganizationChange = () => {
      setOrganization(localStorage.getItem("organization"));
    };

    window.addEventListener("storageOrganizationChanged", handleOrganizationChange);
    return () => {
      window.removeEventListener("storageOrganizationChanged", handleOrganizationChange);
    };
  }, []);
  return (
    <div>
      <Select
        options={usernames}
        placeholder={i18next.t("general:User")}
        labelInValue
        onChange={(selectedObject) => setSelectedUserUUID(selectedObject.value)}
        filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
        style={{width: "200px"}}
      />
      <ConfigProvider locale={locale}>
        <RangePicker
          disabledDate={(current) => {
            return current && current > dayjs().endOf("day");
          }}
          presets={rangePresets}
          onChange={(date, dateString) => setDateSelectorRange({startDate: dateString[0], endDate: dateString[1]})}
          style={{width: "300px", marginLeft: "20px"}}
        />
      </ConfigProvider>
      <Select
        options={languages}
        style={{width: "75px", marginLeft: "20px"}}
        defaultValue={"ru"}
        onChange={(reportLanguage) => setReportLanguage(reportLanguage)}
      />
      <Button
        variant={"solid"}
        style={{marginLeft: "20px"}}
        onClick={() => getReportData()}
        disabled={isReportButtonDisabled}
      >
        {i18next.t("report:Form report")}
      </Button>
      <Divider />
      {isLoadingReport
        ? (
          <Row justify={"center"} align={"middle"}>
            <Col span={11} />
            <Col span={2}>
              <Spin size="large" tip="Формируем отчет" />
            </Col>
            <Col span={11} />
          </Row>
        )
        : <ReportDataTables reportData={reportData} />}
    </div>
  );
};

export default ReportsPage;
